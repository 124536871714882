import { useCallback, useState } from 'react';

import useToggle from '../../../hooks/useToggle';
import { ReviewCardProps } from '../../molecules/ReviewCard/types';

export const useReview = () => {
  const [isOpen, { toggleOn, toggleOff, registerContainerRef }] = useToggle();
  const [openedReview, setOpenedReview] = useState<
    Pick<ReviewCardProps, 'review' | 'author'> | undefined
  >(undefined);

  const onReadMoreClick = useCallback(
    (review: Pick<ReviewCardProps, 'review' | 'author'>) => {
      setOpenedReview(review);
      toggleOn();
    },
    [toggleOn]
  );

  return {
    isOpen,
    openedReview,
    onReadMoreClick,
    onClose: toggleOff,
    ref: registerContainerRef,
  };
};
