import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { settings } from './const';
import * as Styled from './styles';
import { Inner } from '../../styles/Grid/styles';
import Modal from '../../molecules/Modal';
import Heading from '../../molecules/Heading';
import ReviewCard from '../../molecules/ReviewCard';
import ServiceRatings from '../../molecules/ServiceRatings';
import ReviewsSummaryOverview from '../../atoms/ReviewsSummaryOverview';
import { ReviewsCarouselDataProps } from './types';
import { useReview } from './hooks';

const ReviewsCarousel: React.FC<ReviewsCarouselDataProps> = ({ data }) => {
  const { title, subtitle, description, serviceRatings, reviews, buttonLabel, average } = data;
  const { isOpen, onClose, openedReview, ref, onReadMoreClick } = useReview();

  return (
    <Inner>
      <Heading
        {...{ title, subtitle }}
        content={
          <Styled.ReviewsSectionHead>
            <Styled.ReviewsSectionHeadDesc>{description}</Styled.ReviewsSectionHeadDesc>
          </Styled.ReviewsSectionHead>
        }
      />
      {reviews.length && (
        <Styled.ReviewsCarouselWrapper>
          <Slider {...settings}>
            {reviews.map(({ key, author, review }) => (
              <Styled.ReviewsCarouselItem {...{ key }}>
                <ReviewCard {...{ author, review, buttonLabel, onReadMoreClick }} />
              </Styled.ReviewsCarouselItem>
            ))}
          </Slider>
        </Styled.ReviewsCarouselWrapper>
      )}
      <Modal {...{ isOpen, onClose, ref }}>
        {openedReview && <ReviewCard isExpanded {...openedReview} />}
      </Modal>
    </Inner>
  );
};

export default ReviewsCarousel;
