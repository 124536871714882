import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import { ReviewsCarouselSectionProps } from './types';
import { ServiceRatingItemProps } from '../../../molecules/ServiceRatings/types';
import { ReviewItem } from '../../ReviewsCarousel/types';
import { Inner } from '../../../styles/Grid/styles';
import ReviewsCarousel from '../../ReviewsCarousel';

const ReviewsCarouselSection: React.VFC<ReviewsCarouselSectionProps> = ({
  title,
  subtitle,
  description,
  serviceRatings,
  reviews,
  buttonLabel,
}) => {
  const reviewsRates = (reviews ?? []).map((reviewObject) => {
    const { review } = reviewObject ?? {};
    const { rate } = review ?? {};

    return rate ?? 0;
  });

  const average = reviewsRates.reduce((a, b) => a + b, 0) / reviewsRates.length;

  return (
    <Inner>
      <ReviewsCarousel
        data={{
          title: title ?? '',
          subtitle: subtitle ?? '',
          description: description ?? '',
          buttonLabel: buttonLabel ?? '',
          serviceRatings: (serviceRatings ?? []).map<ServiceRatingItemProps>((serviceRating) => {
            const { _key, name, rate, maxRate } = serviceRating ?? {};

            return {
              key: _key ?? '',
              name: name ?? '',
              rate: rate ?? 0,
              maxRate: maxRate ?? 0,
            };
          }),
          average,
          reviews: (reviews ?? []).map<ReviewItem>((item) => {
            const { _key: reviewKey, rate, comment, date } = item?.review ?? {};
            const { _key: authorKey, firstName, lastName, avatar } = item?.author ?? {};

            return {
              key: item?._key ?? '',
              review:
                {
                  key: reviewKey ?? '',
                  rate: rate ?? 0,
                  comment: comment ?? '',
                  date: date ?? '',
                } ?? null,
              author:
                {
                  key: authorKey ?? '',
                  firstName: firstName ?? '',
                  lastName: lastName ?? '',
                  avatar: getImage(avatar),
                } ?? null,
            };
          }),
        }}
      />
    </Inner>
  );
};

export const fragment = graphql`
  fragment ReviewsCarouselSectionFragment on SanityReviewsCarouselSection {
    _key
    _type
    subtitle
    title
    description
    buttonLabel
    reviews {
      _key
      review {
        _key
        comment
        date
        rate
      }
      author {
        _key
        firstName
        lastName
        avatar {
          ...ImageFragment
        }
      }
    }
    serviceRatings {
      _key
      name
      rate
      maxRate
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default ReviewsCarouselSection;
