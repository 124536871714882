import styled from 'styled-components';

import { StyledRatingStarProps } from './types';

export const RatingStars = styled.ul`
  display: inline-flex;
`;

export const RatingStarsIcon = styled.li<StyledRatingStarProps>`
  font-size: 1.5rem;
  height: 1em;

  &:not(:first-of-type) {
    margin-left: 0.5rem;
  }

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.additional.accentGray2 : theme.colors.secondary.hover};
`;
