import React from 'react';

import { LinkMode } from '../../atoms/Link/enums';
import { IconAvatarPlaceholder } from '../../../assets/svg';
import * as Styled from './styles';
import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import RatingStars from '../../atoms/RatingStars';
import { ReviewCardProps } from './types';

const ReviewCard: React.FC<ReviewCardProps> = ({
  author,
  review,
  buttonLabel,
  maxCommentChars = 178,
  onReadMoreClick,
  isExpanded,
}) => {
  const { firstName, lastName, avatar } = author;
  const { date, comment, rate } = review;
  const hasLongComment: boolean = comment.length > maxCommentChars;

  return (
    <Styled.ReviewCard {...{ isExpanded }}>
      <Styled.ReviewCardHeading>
        <Styled.ReviewCardAvatar>
          {avatar.image ? (
            <Image image={avatar.image} alt={avatar.alt} objectFit="contain" />
          ) : (
            <IconAvatarPlaceholder />
          )}
        </Styled.ReviewCardAvatar>
        <Styled.ReviewCardHeadingAside>
          <Styled.ReviewCardName variant="textL">
            {firstName} {lastName}
          </Styled.ReviewCardName>
          <Styled.ReviewCardDate variant="textM">{date}</Styled.ReviewCardDate>
        </Styled.ReviewCardHeadingAside>
      </Styled.ReviewCardHeading>
      <Styled.ReviewCardContent>
        {isExpanded && (
          <Styled.ReviewCardStartRate>
            <RatingStars value={rate} />
          </Styled.ReviewCardStartRate>
        )}
        <Styled.ReviewCardComment variant="textL">
          {comment.length < maxCommentChars || isExpanded
            ? comment
            : `${comment.substring(0, maxCommentChars)}...`}
        </Styled.ReviewCardComment>
        {hasLongComment && onReadMoreClick && !isExpanded && (
          <Styled.ReviewCardAction>
            <Button
              mode={LinkMode.HasUnderline}
              onClick={() =>
                onReadMoreClick({
                  author,
                  review,
                })
              }
            >
              {buttonLabel}
            </Button>
          </Styled.ReviewCardAction>
        )}
      </Styled.ReviewCardContent>
    </Styled.ReviewCard>
  );
};

export default ReviewCard;
