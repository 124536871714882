import React from 'react';

import { IconStar } from '../../../assets/svg';
import { RatingStarsProps } from './types';
import * as Styled from './styles';

const RatingStars: React.FC<RatingStarsProps> = ({ value, maxValue = 5 }) => (
  <Styled.RatingStars {...{ value, maxValue }}>
    {Array.from({ length: maxValue }, (x, index) => (
      <Styled.RatingStarsIcon key={index} isActive={value < index + 1}>
        <IconStar />
      </Styled.RatingStarsIcon>
    ))}
  </Styled.RatingStars>
);

export default RatingStars;
